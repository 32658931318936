.highlight {
    background-color: #fff2ac;
    background-image: linear-gradient(to right, #ffe359 0%, #fff2ac 100%);
}
.critical {
    color: purple
}
.high {
    color: red
}
.medium {
    color: orange
}
.low {
    color: green
}

